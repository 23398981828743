import { FunctionComponent } from 'react'

const Hearto: FunctionComponent = () => (
  <svg
    className="c-hearto"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 75 67"
  >
    <path d="M67.9 8.47C60.49 0 47.29-2.34 37.92 7.11a.9.9 0 01-1.22 0C26.35-2.42 13.4-.1 6.72 8.39-4.77 23.12 1.66 33.86 14.94 47l15 15.22c5.74 5.64 8.75 5.8 14.66 0L59.67 47C73 33.86 80.21 22.48 67.9 8.47z" />
  </svg>
)

export default Hearto
