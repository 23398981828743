import { Link } from 'react-router-dom'
import User from '../../components/User'

import users from '../../users'

const Gallery = () => (
  <div className="c-gallery">
    {Object.values(users).map((user) => (
      <div key={user.name} className="c-gallery__item">
        <Link to={`/presentation/${user.slug}`}>
          <User {...user} />
        </Link>
      </div>
    ))}
  </div>
)

export default Gallery
