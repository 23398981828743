import shuffle from '../utils/shuffle'

import borbPhoto from '../assets/borb.jpg'
import daniPhoto from '../assets/dani.jpg'
import mikuPhoto from '../assets/miku.jpg'
import yenAndMaxPhoto from '../assets/yen-and-max.jpg'
import liliPhoto from '../assets/lili.jpg'
import maxPhoto from '../assets/max.jpg'
import mattPhoto from '../assets/matt.jpg'
import rachelPhoto from '../assets/rachel.jpg'
import robinAndSamPhoto from '../assets/robin-and-sam.jpg'
import paulPhoto from '../assets/paul.jpg'
import felixPhoto from '../assets/felix.jpg'
import benAndHarleyPhoto from '../assets/ben-and-harley.jpg'
import holliePhoto from '../assets/hollie.jpg'
import lauraPhoto from '../assets/laura.jpg'
import riotAndSoraPhoto from '../assets/riot-and-sora.jpg'
import saraPhoto from '../assets/sara.jpg'

export interface UserInfo {
  name: string
  slug: string
  image?: string
}

const WIZZY_USERS: UserInfo[] = [
  {
    name: 'Borb',
    slug: 'borb',
    image: borbPhoto
  },
  {
    name: 'dani',
    slug: 'dani',
    image: daniPhoto
  },
  {
    name: 'Yến and Max',
    slug: 'yen-and-max',
    image: yenAndMaxPhoto
  },
  {
    name: 'Hatsune Miku',
    slug: 'miku',
    image: mikuPhoto
  }
]

const FRIEND_USERS: UserInfo[] = [
  {
    name: 'Felix',
    slug: 'felix',
    image: felixPhoto
  },
  {
    name: 'Ben and Harley',
    slug: 'ben-and-harley',
    image: benAndHarleyPhoto
  },
  {
    name: 'Hollie',
    slug: 'hollie',
    image: holliePhoto
  },
  {
    name: 'Laura',
    slug: 'laura',
    image: lauraPhoto
  },
  {
    name: 'Lili',
    slug: 'lili',
    image: liliPhoto
  },
  {
    name: 'Matt',
    slug: 'matt',
    image: mattPhoto
  },
  {
    name: 'Max',
    slug: 'max',
    image: maxPhoto
  },
  {
    name: 'Paul Bagz',
    slug: 'paul',
    image: paulPhoto
  },
  {
    name: 'Rachel',
    slug: 'rachel',
    image: rachelPhoto
  },
  {
    name: 'Riot and Sora',
    slug: 'riot-and-sora',
    image: riotAndSoraPhoto
  },
  {
    name: 'Robin and Sam',
    slug: 'robin-and-sam',
    image: robinAndSamPhoto
  },
  {
    name: 'Sara',
    slug: 'sara',
    image: saraPhoto
  }
]

const USERS = [...WIZZY_USERS, ...shuffle(FRIEND_USERS)]

export default USERS
