import { FunctionComponent, useMemo } from 'react'

import users from '../../users'

import Hearto from '../../components/Hearto'
import User from '../../components/User'

const WelcomeMessage: FunctionComponent<{ message: string }> = ({
  message
}) => (
  <>
    {message.split('').map((character) => (
      <span key={character} className="c-welcome__character">
        {character}
      </span>
    ))}
  </>
)

const Welcome: FunctionComponent = () => {
  const wizzies = useMemo(
    () =>
      users.filter(
        (user) => user.slug === 'dani' || user.slug === 'yen-and-max'
      ),
    []
  )

  return (
    <div className="c-welcome">
      <div className="c-welcome__users">
        <User {...wizzies[0]} />
        <div className="c-welcome__spacer" />
        <User {...wizzies[1]} />
      </div>

      <Hearto />

      <div>
        <div className="c-welcome__message c-welcome__message--big">
          <WelcomeMessage message="happy" />
          <span className="c-welcome-message__spacer" />
          <WelcomeMessage message="christmas!" />
        </div>

        <div className="c-welcome__message">
          sorry about 2020, but we love you
        </div>
      </div>

      <Hearto />
    </div>
  )
}

export default Welcome
