import { FunctionComponent } from 'react'
import classnames from 'classnames'

interface UserProps {
  name: string
  image?: string
  className?: string
}

const User: FunctionComponent<UserProps> = ({
  name,
  image = 'http://placekitten.com/1280/720',
  className
}) => (
  <figure className={classnames('c-user', className)}>
    <img src={image} alt="" />
    <figcaption className="c-user__name">{name}</figcaption>
  </figure>
)

export default User
