import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import TitleBar from './components/TitleBar'

import Gallery from './modules/Gallery'
import Presentation from './modules/Presentation'
import Preloader from './Preloader'

import './styles/witch-zoom.scss'

const App = () => (
  <Preloader>
    <BrowserRouter>
      <div id="🪟" className="c-window">
        <TitleBar />
        <div className="c-window__body" />
      </div>

      <div id="🎥">
        <div className="o-content-wrapper">
          <Switch>
            <Route path="/gallery" component={Gallery} />
            <Route path="/presentation/:userParam?" component={Presentation} />
            <Redirect to="/presentation" />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  </Preloader>
)

export default App
