import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import users from '../../users'

import User from '../../components/User'
import Welcome from './Welcome'
import UserNavigation from './UserNavigation'

interface PresentationRouteParams {
  userParam?: string
}

const useCurrentUser = () => {
  const { userParam } = useParams<PresentationRouteParams>()

  return useMemo(() => users.find((user) => user.slug === userParam), [
    userParam
  ])
}

const Presentation = () => {
  const currentUser = useCurrentUser()

  return (
    <div className="c-presentation">
      <UserNavigation users={users} className="c-presentation__users" />

      <div className="c-presentation__focus">
        {currentUser ? <User {...currentUser!} /> : <Welcome />}
      </div>
    </div>
  )
}

export default Presentation
