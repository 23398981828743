import { FunctionComponent, useEffect } from 'react'
import users from './users'
import promiseMeAnImage from './utils/promiseMeAnImage'

const USER_IMAGES = users
  .filter((user) => !!user.image)
  .map((user) => promiseMeAnImage(user.image!))

const showApp = () =>
  setTimeout(() => {
    document.getElementById('💭')!.classList.add('‼️')
    document.getElementById('✨')!.classList.add('‼️')
  }, 666)

const Preloader: FunctionComponent = ({ children }) => {
  useEffect(() => {
    const waitForLoad = async () => {
      await Promise.all(USER_IMAGES)
      showApp()
    }

    waitForLoad()
  }, [])

  return <>{children}</>
}

export default Preloader
