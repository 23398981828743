import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'

import User from '../../components/User'

import { UserInfo } from '../../users'

interface PresentationProps {
  users: UserInfo[]
  className?: string
}

const PAGE_SIZE = 4

const Presentation: FunctionComponent<PresentationProps> = ({
  users,
  className
}) => {
  const MAX_PAGES = useMemo(() => users.length / PAGE_SIZE, [users])
  const [page, setPage] = useState(0)
  const shownUsers = useMemo(
    () => users.slice(page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE),
    [users, page]
  )

  const onNextPage = useCallback(() => {
    setPage((page + 1) % MAX_PAGES)
  }, [page, setPage, MAX_PAGES])

  const onPreviousPage = useCallback(() => {
    const nextIndex = page - 1 < 0 ? MAX_PAGES - 1 : page - 1
    setPage(nextIndex)
  }, [page, setPage, MAX_PAGES])

  return (
    <div className={classnames('c-user-navigation', className)}>
      <button
        className="c-user-navigation__button c-user-navigation__button--previous"
        onClick={onPreviousPage}
      >
        <svg
          className="c-user-navigation__arrow"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28 44"
        >
          <path d="M2.4,23.58,21.68,41.74a2.23,2.23,0,0,0,3.76-1.62V3.79a2.23,2.23,0,0,0-3.76-1.63L2.4,20.33A2.24,2.24,0,0,0,2.4,23.58Z" />
        </svg>
      </button>

      <div className="c-user-navigation__items">
        {shownUsers.map((user) => (
          <NavLink
            key={user.slug}
            className="c-user-navigation__item"
            activeClassName="c-user-navigation__item--current"
            to={`/presentation/${user.slug}`}
          >
            <User {...user} />
          </NavLink>
        ))}
      </div>

      <button
        className="c-user-navigation__button c-user-navigation__button--next"
        onClick={onNextPage}
      >
        <svg
          className="c-user-navigation__arrow"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 28 44"
        >
          <path d="M26,20.33,6.76,2.16A2.23,2.23,0,0,0,3,3.79V40.12a2.23,2.23,0,0,0,3.76,1.62L26,23.58A2.23,2.23,0,0,0,26,20.33Z" />
        </svg>
      </button>
    </div>
  )
}

export default Presentation
